// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useSpring } from '@react-spring/core'
import { a as three } from '@react-spring/three'
import { ContactShadows, Environment, useGLTF } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import { breakpoints } from '@utils/constants'
import { Suspense, useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { useBreakpoint } from 'use-breakpoint'
import Button from './common/Button'
import ArrowSvg from './common/ArrowSvg'

const vec = new THREE.Vector3()

function Model({ open, hinge, ...props }) {
    const group = useRef<any>()
    const { breakpoint } = useBreakpoint(breakpoints, 'mobile')
    const { nodes, materials } = useGLTF('/mac-draco.glb')
    const [hovered, setHovered] = useState(false)
    useEffect(() => {
        document.body.style.cursor = hovered ? 'pointer' : 'auto'
    }, [hovered])

    // Make it float in the air when it's opened
    useFrame((state) => {
        const t = state.clock.getElapsedTime()
        state.camera.position.lerp(vec.set(0, 0, open ? breakpoint === 'mobile' ? -40 : -24 : breakpoint === 'mobile' ? -40 : -32), 0.1)
        state.camera.lookAt(0, 0, 0)
        group.current.rotation.x = THREE.MathUtils.lerp(
            group.current.rotation.x,
            open ? Math.cos(t / 2) / 8 + 0.25 : 0,
            0.1
        )
        group.current.rotation.y = THREE.MathUtils.lerp(
            group.current.rotation.y,
            open ? Math.sin(t / 4) / 4 : 0,
            0.1
        )
        group.current.rotation.z = THREE.MathUtils.lerp(
            group.current.rotation.z,
            open ? Math.sin(t / 4) / 4 : 0,
            0.1
        )
        group.current.position.y = THREE.MathUtils.lerp(
            group.current.position.y,
            open ? (-2 + Math.sin(t)) / 3 : -4.3,
            0.1
        )
    })

    return (
        <group
            ref={group}
            {...props}
            onPointerOver={(e) => {
                e.stopPropagation()
                setHovered(true)
            }}
            onPointerOut={() => setHovered(false)}
            dispose={null}
        >
            <three.group rotation-x={hinge} position={[0, -0.04, 0.41]}>
                <group position={[0, 2.96, -0.13]} rotation={[Math.PI / 2, 0, 0]}>
                    <mesh
                        material={materials.aluminium}
                        geometry={nodes.Cube008.geometry}
                    />
                    <mesh
                        material={materials['matte.001']}
                        geometry={nodes.Cube008_1.geometry}
                    />
                    <mesh
                        material={materials['screen.001']}
                        geometry={nodes.Cube008_2.geometry}
                    />
                </group>
            </three.group>
            <mesh
                material={materials.keys}
                geometry={nodes.keyboard.geometry}
                position={[1.79, 0, 3.45]}
            />
            <group position={[0, -0.1, 3.39]}>
                <mesh
                    material={materials.aluminium}
                    geometry={nodes.Cube002.geometry}
                />
                <mesh
                    material={materials.trackpad}
                    geometry={nodes.Cube002_1.geometry}
                />
            </group>
            <mesh
                material={materials.touchbar}
                geometry={nodes.touchbar.geometry}
                position={[0, -0.03, 1.2]}
            />
        </group>
    )
}

export default function Hero() {
    const [open, setOpen] = useState<boolean>(false)
    const props = useSpring({ open: Number(open) })

    return (
        <div

            className='relative transition-all duration-500 ease-in-out mt-100px h-500px md:mb-100px flex flex-col items-center <sm:mt-70px <sm:h-h-400px'
        >

            <div className={`absolute flex flex-col justify-start items-center transition-all duration-300 ease-in-out ${open ? 'opacity-0 -z-1 mt-50px' : 'opacity-100 z-1'}`}>
                <p className='text-26px text-light-blue font-400 <lg:text-20px <sm:text-16px'>Digital solutions that exceed expectations</p>
                <div className='relative'>
                    <img
                        src="/icons/pheno-logo.svg"
                        alt="pheno agency logo"
                        className='home-masked-logo absolute -top-100px -left-120px w-278px h-278px -z-1 <lg:w-197px <lg:h-197px <lg:-left-85px <lg:-top-60px <sm:w-124px <sm:h-124px <sm:-left-55px <sm:-top-55px' />

                    <h1 className='leading-16 mb-20px text-64px text-dark-blue font-700 font-Montserrat <lg:text-48px <sm:text-32px <sm:leading-12'>That's the Pheno way</h1>
                </div>
                <div className='flex items-center justify-center gap-4 <sm:flex-col-reverse <sm:gap-2'>

                    <Button title='Work with us' link="/contact-us" titleClassName='!text-18px <md:!text-16px' />
                    <Button
                        title="Book a call"
                        blank
                        link="https://calendly.com/pheno_agency/small-talk"
                        buttonClassName='book-a-call-bg gap-2 w-13rem <lg:w-11rem'
                        titleClassName='book-a-call-title font-500 !text-4 <md:!text-16px'
                        icon="/icons/calendar-icon.svg"
                    />
                </div>
                <ArrowSvg className={'SVG w-4rem <lg:w-4rem <lg:h-3rem <sm:w-2.5rem transform scale-x-[1] rotate-30 absolute right-120px -bottom-90px transition-all duration-300 ease-in-out z-2 <lg:right-40px <sm:right-20px <sm:top-10rem'} />
            </div>
            <Canvas dpr={[1, 2]} camera={{ position: [1000, 0, 0], fov: 30, }}
                className={`relative transition-all duration-500 ease-in-out ${open ? 'top-0px <md:-top-80px' : '-top-100px <md:-top-120px'}`}
            >
                <three.pointLight
                    position={[10, 10, 10]}
                    intensity={1.5}
                    color={props.open.to([0, 1], ['#f0f0f0', '#d25578'])}
                />
                <Suspense fallback={null}>
                    <group
                        rotation={[0, Math.PI, 0]}
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpen(!open)
                        }}
                    >
                        <Model open={open} hinge={props.open.to([0, 1], [1.575, -0.425])} />
                    </group>
                    <Environment files='/hdri/city.hdr' />
                </Suspense>
                <ContactShadows
                    rotation-x={Math.PI / 2}
                    position={[0, -4.5, 0]}
                    opacity={0.4}
                    width={10}
                    height={open ? 3 : 15}
                    blur={2}
                    far={4.5}
                />
            </Canvas>
        </div>
    )
}
